import BaseButton from '../components/base/BaseButton'
import BaseCard from '../components/base/BaseCard'
import Layout from "../components/custom/Layout"
import Loading from '../components/custom/Loading'
import Modal from '../components/general/Modal'

export default function (Vue) {
    Vue.component("BaseButton", BaseButton)
    Vue.component("BaseCard", BaseCard)
    Vue.component("Layout", Layout)
    Vue.component("Loading", Loading)
    Vue.component("Modal", Modal)
}
