<template>
    <div :class="classes" @click="onClick">
        <div class="card-header">
            <slot name="header"></slot>
        </div>
        <div class="card-body">
            <slot name="body"></slot>
        </div>
        <div class="card-footer">
            <slot name="footer"></slot>
        </div>
        <slot />
    </div>
</template>

<script>
export default {
    name: 'base-card',
    props: {
        shadow: {
            type: Boolean,
            required: false,
        },
        border: {
            type: Boolean,
            required: false,
        },
        cardFull: {
            type: Boolean,
            required: false,
        },
        active: {
            type: Boolean,
            required: false,
        },
        hidePadding: {
            type: Boolean,
            required: false,
        },
    },
    computed: {
        classes() {
            return {
                'card-wrap': true,
                'card-shadow': this.shadow,
                'card-border': this.border,
                'card-full': this.cardFull,
                'card-active': this.active,
                'card-no-padding': this.hidePadding,
            };
        },
    },
    methods: {
        onClick() {
            this.$emit('onClick');
        },
    },
};
</script>

<style scoped>
.card-wrap {
    padding: 0;
    background-color: #ffffff;
    font-family: Sukhumvit Set Medium;
    font-weight: 500;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
}
.card-header {
}
.card-no-padding {
    padding: 0px !important;
}
.card-shadow {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.card-border {
    border: 1px solid #ccd0d1;
}
.card-body {
    flex: 1;
    position: relative;
}
.card-full {
    /* max-height: calc(100vh); */
}
.card-active {
    background-color: rgba(96, 177, 129, 0.5);
    border: 1px solid rgba(96, 177, 129, 0.5);
}
</style>
