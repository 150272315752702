<template>
    <div :class="classes" >
        <slot></slot>
    </div>
</template>

<script>
export default {
    computed: {
        classes() {
            return {
                'content': true,
            };
        },
    },
}
</script>

<style scoped>
.content-form {
    /* max-width: 556px; */
	position: relative;
    /* min-height: 100vh; */
    box-shadow: 0px 0px 10px grey;
    background-color: #ffffff;
    align-self: center;
}
</style>